












import Vue from "vue";
import PolizaService, { GetPolizaDto } from "@/api/poliza/PolizaService";
export default Vue.extend({
  data() {
    return {
      idPoliza: this.$route.params.id,
      loading: false,
      poliza: null as null | GetPolizaDto
    };
  },
  methods: {
    loadPoliza() {
      this.loading = true;
      PolizaService.get(this.idPoliza)
        .then(response => {
          this.poliza = response;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  created() {
    this.loadPoliza();
  }
});
